import React, { FC, useState, useEffect } from 'react';
import { BsAsterisk } from 'react-icons/bs';
import { FormControllerContainerProps } from './FormControlContainer.types';
import { ControlState } from '../../../../sysObjects/common.types';
import SideBar from '../../ReusableBlocks/SideBar/SideBar';
import StatusBlock from '../../ReusableBlocks/StatusBlock/StatusBlock';

import './FormControlContainer.css';
const FormControllerContainer: FC<FormControllerContainerProps> = (props) => {
  const [currentBoxState, setBoxState] = useState<ControlState>(props.boxState);

  useEffect(() => {
    setBoxState(props.boxState);
  }, [props.boxState]);

  const renderRequired = () => {
    return !props.requiredText ? null : (
      <div key={`container_required_${props.id}`} className="required-bar">
        <BsAsterisk size={10} />
        <label className="required">{props.requiredText}</label>
      </div>
    );
  };

  const renderErrorMessage = () => {
    return currentBoxState === 'negative' ? (
      <div key={`container_error_${props.id}`} className="error-bar error-text">
        {props.errorMessage}
      </div>
    ) : null;
  };

  const renderHelpMessage = () => {
    return props.helpMessage ? (
      <div key={`container_message_${props.id}`} className="help-bar help-text">
        {props.helpMessage}
      </div>
    ) : null;
  };

  return (
    <div
      className={`form-control-container lexxic-text ${
        props.hidden === true ? 'lexxic-hidden' : ''
      }`}
    >
      {props.hideBox !== true ? (
        <SideBar
          boxState={currentBoxState}
          key={`container_${props.id}`}
          includeInnerBar={true}
          readonly={props.readonly}
        />
      ) : null}
      <div className="control-panel">
        {props.hideBox !== true ? (
          <div className="top-bar">
            <div className="label-bar">
              <label className="strong" htmlFor={props.id}>
                {props.label}
              </label>
            </div>
            {renderRequired()}
          </div>
        ) : null}
        <div className="control-bar">
          <div className="control">{props.children}</div>
          <StatusBlock
            boxSize="medium"
            id={props.id}
            boxState={props.readonly === true ? 'positive' : currentBoxState}
            showIcon={true}
            readonly={props.readonly}
          />
        </div>
        <div className="info-bar">
          {renderHelpMessage()}
          {renderErrorMessage()}
        </div>
      </div>
    </div>
  );
};

export default FormControllerContainer;
