import React, { FC } from 'react';
import { InformationButtonProps } from './InformationButton.types';
import './InformationButton.css';
import Button from '../../Common/Button/Button';

const InformationButton: FC<InformationButtonProps> = ({
  buttonDetails,
  children,
}) => {
  return (
    <div className='information-button-container'>
      <div>
        <Button
          itemKey={buttonDetails.itemKey}
          ariaDescribedBy={buttonDetails.ariaDescribedBy}
          ariaLabel={buttonDetails.ariaLabel}
          clickEvent={buttonDetails.clickEvent}
          disabled={buttonDetails.disabled}
          label={buttonDetails.label}
          mode={buttonDetails.mode}
        >
          {buttonDetails.children}
        </Button>
      </div>
      <div className='lexxic-text'>{children}</div>
    </div>
  );
};

export default InformationButton;
