// src/pages/UserPage/UserPage.tsx
import React from 'react';
import { useMsal } from '@azure/msal-react';
import image from '../../lexxic.png';
import Button from '../../systemComponents/sharedControls/Common/Button/Button';

const versionMin = '12_06_2024_09_09_31';

const LandingPage: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect();
  };

  return (
    <div className="Center-Placer">
      <div className="Center-Div">
        <div>
          <img src={image} />
        </div>
        <div style={{backgroundColor: 'white'}}>
          <div className='header'>Login</div>
          <div>
            Version Number: {`${versionMin}`}
            <Button
              itemKey="login"
              clickEvent={() => {
                handleLogin();
              }}
              mode="positive"
              label="Sign In"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
