import React, { FC } from 'react';
import { BaseBoxProps } from './BaseBox.types';
import StatusBlock from '../StatusBlock/StatusBlock';
import SideBar from '../SideBar/SideBar';

import './BaseBox.css';
import Button from '../../Common/Button/Button';

const BaseBox: FC<BaseBoxProps> = (props: BaseBoxProps) => {
  const renderButtons = () => {
    return !props.primaryButton ? null : (
      <div className="buttons">
        <Button
          itemKey={`primaryButton_${props.modalKey}`}
          children={props.primaryButton.children}
          clickEvent={props.primaryButton.clickEvent}
          label={props.primaryButton.label}
          mode={props.controlState}
        />
      </div>
    );
  };

  return (
    <div className="message-box" key={props.modalKey}>
      <SideBar key={props.modalKey} boxState={props.controlState} includeInnerBar={true} />
      <div className="message-box-header">
        <StatusBlock
          boxSize="small"
          key={props.modalKey}
          id={props.modalKey}
          boxState={props.controlState}
          showIcon={false}
        />
        <label className="lexxic-text message-box-heading">{props.heading}</label>
      </div>
      <div className="message-box-body">
        <StatusBlock
          boxSize="small"
          key={`${props.modalKey}_block`}
          id={props.modalKey}
          boxState={props.controlState}
          showIcon={true}
        />
        <div className="lexxic-text body-text">{props.children || props.bodyText}</div>
        {!props.errorString ? null : (
          <>
            <StatusBlock
              boxSize="small"
              key={`${props.modalKey}_error_block`}
              id={props.modalKey}
              boxState={props.controlState}
              showIcon={false}
            />
            <div className="lexxic-text body-text">{props.errorString}</div>
          </>
        )}
      </div>
      {renderButtons()}
    </div>
  );
};

export default BaseBox;
