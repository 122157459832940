import React from 'react';
import './Breadcrumbs.css';
import { BreadcrumbProps } from './Breadcrumbs.types';

import { BsHouse } from "react-icons/bs";

export const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
    return (
        <>
            {(props.to === '/') &&
                <li className="breadcrumb">
                    <div className="breadcrumb_content breadcrumb_content--home" onClick={() => { if (props.onClick && props.to) props.onClick(props.to) }} >
                        <BsHouse />
                    </div>
                </li>
            }
            {(props.to != '/') &&
                <li aria-label={props.labelText} className="breadcrumb" onClick={() => { if (props.onClick && props.to) props.onClick(props.to) }} >
                    <div className="breadcrumb_content">
                        {props.labelText}
                    </div>
                </li>
            }
        </>
    )
};
export default Breadcrumb;