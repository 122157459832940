import React from 'react';
import usr from '../../utils/userUtils/UserActions';
import { useIntl } from 'react-intl';
import Enumerations, {
  getHeaderInfo,
} from '../../utils/CommonUtils/CommonHelpers';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import LoadingDiv from '../../components/LoadingDiv/LoadingDiv';

import messages from './locales/en.json';
import CustomerDetails from '../../objects/APIModels/CustomerDetails.types';
import userActions from '../../utils/userUtils/UserActions';
import { UserClaimsContext } from '../../sysObjects/Auth/UserClaimsContext';
import { ControlState, KeyValuePair } from '../../sysObjects/common.types';
import { FormControlOnChangeData, FormControlRef } from '../../systemComponents/sharedControls/FormControls/FormControlContainer/FormControlContainer.types';
import { CommonModalErrorProps } from '../../systemComponents/sharedControls/Common/CommonModal/CommonModal.types';
import CommonModal from '../../systemComponents/sharedControls/Common/CommonModal/CommonModal';
import FormContainer from '../../systemComponents/sharedControls/FormControls/FormContainer/FormContainer';
import FormTextCapture from '../../systemComponents/sharedControls/FormControls/FormTextCapture/FormTextCapture';
import FormDropDown from '../../systemComponents/sharedControls/FormControls/FormDropDown/FormDropDown';
import InformationButton from '../../systemComponents/sharedControls/TaskBase/InformationButton/InformationButton';

const EditDetails: React.FC = () => {
  const intl = useIntl();
  // const messages = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { userClaims } = React.useContext(UserClaimsContext);

  const [formData, setFormData] = React.useState<CustomerDetails.Customer>(
    usr.getDefaultDetails(userClaims?.user?.userAccountId!)
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [employmentTypes, setEmploymentTypes] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [pronouns, setPronouns] = React.useState<KeyValuePair<number>[]>([]);
  const [customerId, setCustomerId] = React.useState<string | undefined>(
    undefined
  );

  const [tabs, SetTabs] = React.useState<KeyValuePair<string>[]>(
    messages.Tabs.map((name) => {
      return {
        key: name,
        value: 'neutral',
      };
    })
  );

  const [errorProps, setErrorProps] = React.useState<CommonModalErrorProps>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });

  const formRefs = [
    [React.useRef<FormControlRef>(null), React.useRef<FormControlRef>(null)],
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
  ];

  const setError = (
    message: string | React.ReactNode,
    errorString?: string,
    ent?: () => void
  ) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: messages.ErrorDetails.Heading,
      errorString: errorString,
      children: message,
      buttonDetails: {
        label: messages.ErrorDetails.Button,
        clickEvent: event,
      },
      show: true,
    });
  };

  React.useEffect(() => {
    if (userClaims === null) {
      setError(messages.ErrorDetails.Message);
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      UserAccountId: userClaims!.user!.userAccountId,
    }));

    Enumerations.getPronounsAsync().then((rst) => {
      if (rst.isFailure) {
        setError(messages.ErrorDetails.Message);
        return;
      }
      setPronouns(rst.result || []);
    });

    Enumerations.getEmploymentTypeAsync().then((rst) => {
      if (rst.isFailure) {
        setError(messages.ErrorDetails.Message);
        return;
      }
      setEmploymentTypes(rst.result || []);
    });

    userActions
      .getCustomerProfileAsync(getHeaderInfo(userClaims, instance))
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure) {
          setError(messages.ErrorDetails.Message);
          return;
        }
        if (result.result === null || result.result === undefined) {
          // This is correct, if the account exists but not the customer then we return 204
          return;
        }
        setCustomerId(result.result!.userAccountId);
        setFormData(result.result!);
        setIsLoading(false);
      });
  }, []);

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleDropDownChanged = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >
  ) => {
    const value =
      typeof result.value === 'string'
        ? parseInt(result.value, 10)
        : result.value;

    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: value,
    }));
  };

  const handleEducationChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >
  ) => {
    console.log(result);
    setFormData((prevData) => ({
      ...prevData,
      customerInEducation: {
        ...prevData.customerInEducation!,
        [result.fieldId]: result.value,
      },
    }));
  };

  const save = () => {
    let allValid: boolean[] = [];

    formRefs.forEach((ref, i) => {
      const validationResults = ref.map((innerRef) =>
        innerRef.current?.triggerValidation()
      );
      const tabValid = validationResults.every((result) => result === true);
      allValid.push(tabValid);
      SetTabs((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[i] = {
          ...updatedPages[i],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
    });

    const pageValid = allValid.every((isValid) => isValid);
    if (!pageValid) {
      return;
    }

    usr
      .saveUserDetailsAsync(
        getHeaderInfo(userClaims, instance),
        formData,
        customerId
      )
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure) {
          setError(messages.ErrorDetails.SaveError, '', () =>
            setErrorProps({ ...errorProps, show: false })
          );
          return;
        }

        navigate('/');
      });
  };

  return isLoading ? (
    <LoadingDiv />
  ) : errorProps.show ? (
    <div>
      <CommonModal
        headerText={errorProps.headerText}
        buttonDetails={errorProps.buttonDetails}
        show={errorProps.show}
        modalType="ErrorMessage"
        modalKey="message"
      >
        {errorProps.children || errorProps.bodyText}
      </CommonModal>
    </div>
  ) : (
    <div className="Main-Form-Layout">
      <FormContainer
        pages={[
          {
            mode: tabs[0].value as ControlState,
            name: tabs[0].key,
            content: (
              <>
                <FormTextCapture
                  fieldId="preferredName"
                  ref={formRefs[0][0]}
                  id="preferredName"
                  label={messages.FormLabels.PreferredName}
                  requiredDetails={{
                    formLabel: `${messages.Common.Required}`,
                    message: `${messages.FormLabels.PreferredName} ${messages.Common.RequiredMessage}`,
                  }}
                  textInputType="text"
                  value={formData.preferredName}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormDropDown
                  fieldId="pronouns"
                  ref={formRefs[0][1]}
                  defaultText={messages.Common.Select}
                  id="pronouns"
                  requiredDetails={{
                    formLabel: `${messages.Common.Required}`,
                    message: `${messages.FormLabels.Pronouns} ${messages.Common.RequiredMessage}`,
                  }}
                  label={messages.FormLabels.Pronouns}
                  value={formData.pronouns}
                  items={pronouns}
                  onChange={handleDropDownChanged}
                />
              </>
            ),
          },
          {
            mode: tabs[1].value as ControlState,
            name: tabs[1].key,
            content: (
              <>
                <FormTextCapture
                  fieldId="employmentContactName"
                  ref={formRefs[1][0]}
                  id="employmentContactName"
                  label={messages.FormLabels.EmpContactName}
                  textInputType="text"
                  value={formData.employmentContactName}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  fieldId="lengthOfEmployment"
                  ref={formRefs[1][1]}
                  id="lengthOfEmployment"
                  label={messages.FormLabels.LengthOfEmployment}
                  textInputType="text"
                  value={formData.lengthOfEmployment}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormDropDown
                  fieldId="employmentType"
                  ref={formRefs[1][2]}
                  id="employmentType"
                  label={messages.FormLabels.EmploymentType}
                  defaultText={messages.Common.Select}
                  value={formData.employmentType}
                  items={employmentTypes}
                  requiredDetails={{
                    formLabel: `${messages.Common.Required}`,
                    message: `${messages.FormLabels.EmploymentType} ${messages.Common.RequiredMessage}`,
                  }}
                  onChange={handleDropDownChanged}
                />
                <FormTextCapture
                  fieldId="roleAndResponsibilities"
                  ref={formRefs[1][3]}
                  id="roleAndResponsibilities"
                  label={messages.FormLabels.RoleAndResponsibilities}
                  textInputType="text"
                  value={formData.roleAndResponsibilities}
                  maxLength={250}
                  onChange={handleFormChange}
                />
                <FormTextCapture
                  fieldId="employmentHistory"
                  ref={formRefs[1][4]}
                  id="employmentHistory"
                  label={messages.FormLabels.EmploymentHistory}
                  textInputType="text"
                  value={formData.employmentHistory}
                  maxLength={250}
                  onChange={handleFormChange}
                />
              </>
            ),
          },
          {
            mode: tabs[2].value as ControlState,
            name: tabs[2].key,
            content: (
              <>
                <FormTextCapture
                  fieldId="universityCollegeName"
                  ref={formRefs[2][0]}
                  id="universityCollegeName"
                  label={messages.FormLabels.InstitutionName}
                  textInputType="text"
                  value={formData.customerInEducation!.universityCollegeName}
                  maxLength={250}
                  onChange={handleEducationChange}
                />
                <FormTextCapture
                  fieldId="courseName"
                  ref={formRefs[2][1]}
                  id="courseName"
                  label={messages.FormLabels.CourseName}
                  textInputType="text"
                  value={formData.customerInEducation!.courseName}
                  maxLength={250}
                  onChange={handleEducationChange}
                />
                <FormTextCapture
                  fieldId="lengthOfCourse"
                  ref={formRefs[2][2]}
                  id="lengthOfCourse"
                  label={messages.FormLabels.lengthOfCourse}
                  textInputType="text"
                  value={formData.customerInEducation!.lengthOfCourse}
                  maxLength={250}
                  onChange={handleEducationChange}
                />
                <FormTextCapture
                  fieldId="yearOfStudy"
                  ref={formRefs[2][3]}
                  id="yearOfStudy"
                  label={messages.FormLabels.YearOfStudy}
                  textInputType="number"
                  value={formData.customerInEducation!.yearOfStudy}
                  maxLength={4}
                  onChange={handleEducationChange}
                />
              </>
            ),
          },
        ]}
        key="user"
      />
      <InformationButton
        key="informationSave"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: () => save(),
          label: messages.Summary.Button,
          mode: 'positive',
        }}
      >
        <>{messages.Summary.Label}</>
      </InformationButton>
    </div>
  );
};
export default EditDetails;
