import React from 'react';
import './Footer.css';
import { FooterProps } from './Footer.types';

export const LexxicFooter: React.FC<FooterProps> = (props) => {
  return (
    <div className="App-Footer lexxic-text">
      <div>{props.title}</div>
      <div   className="footer__imageContainer">
        <img src={props.image} height={20} width={38} alt="Lexxic Logo" />
      </div>
      <div>{props.address}</div>
    </div>
  );
};
export default LexxicFooter;
