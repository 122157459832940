import { LogLevel } from "@azure/msal-browser";
import { AuthenticatedAPIModel, BaseAPIModel, ConfirmAPIModel, CreateAPIModel } from "../../sysObjects/common.types";
import { FunctionalResult } from "../../sysObjects/FunctionalResult";
import SharedLogger from "../common/logging/SharedLogger";

const authorizationHeader = 'Authorization';

const createBearerToken = (accessToken: string) => {
  return `Bearer ${accessToken}`;
}

/**
 * Handles HTTP fetch requests and returns a FunctionalResult.
 * @param {AuthenticatedAPIModel} the Api Details to pass in
 * @param {T} defaultObj - The default object to return if the fetch is successful but no data is returned.
 * @returns {Promise<FunctionalResult<T>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const fetchAction = async <T>(
  obj: AuthenticatedAPIModel,
  defaultObj?: T
): Promise<FunctionalResult<T>> => {
  try {
    const method = obj.method || 'GET';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
          'X-User-Identifier': obj.userId,
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        }
        : {
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        },
    };

    SharedLogger(LogLevel.Info, {
      message: 'Fetch Called',
      Options: options,
      passedParams: obj,
    });
    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    if (response.status === 204) {
      return FunctionalResult.Success(defaultObj);
    }

    const data = await response.json();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

export const fetchActionNoAuth = async <T>(
  obj: BaseAPIModel
): Promise<FunctionalResult<T>> => {
  try {
    const method = obj.method || 'GET';
    const options: RequestInit = {
      method: method,
      headers: { 'Content-Type': 'application/json' }
    };

    SharedLogger(LogLevel.Info, {
      message: 'Fetch No Auth Called',
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    const data = await response.json();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP confirmation actions. This doesn't read the body
 * @param {CreateAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<void>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const confirmAction = async (
  obj: ConfirmAPIModel
): Promise<FunctionalResult<void>> => {
  try {
    const method = obj.method || 'POST';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
          'X-User-Identifier': obj.userId,
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        }
        : {
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        },
    };

    if (obj.formData && method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify({ item: obj.formData });
    }

    SharedLogger(LogLevel.Info, {
      message: 'Confirm Called',
      Options: options,
      passedParams: obj,
    });
    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }
    return FunctionalResult.Success();
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP Post for confirmation purposes and returns a FunctionalResult.
 * @param {CreateAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<string>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const CreateAction = async (
  obj: CreateAPIModel
): Promise<FunctionalResult<string>> => {
  try {
    const method = obj.method || 'POST';

    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
          'X-User-Identifier': obj.userId,
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        }
        : {
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        },
    };

    if (method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify({ item: obj.formData });
    }

    SharedLogger(LogLevel.Info, {
      message: 'Create Called',
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    const data = await response.text();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP fetch requests for confirmation purposes and returns a FunctionalResult.
 * @param {CreateAPIModel} the Api Details to pass in
 * @returns {Promise<FunctionalResult<string>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const UpdateAction = async (
  obj: CreateAPIModel
): Promise<FunctionalResult<string>> => {
  try {
    const method = obj.method || 'PUT';
    const options: RequestInit = {
      method: method,
      headers: obj.userId
        ? {
          'X-User-Identifier': obj.userId,
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        }
        : {
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(obj.accessToken)
        },
    };

    if (method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify({ item: obj.formData });
    }

    SharedLogger(LogLevel.Info, {
      message: 'Updated Called',
      Options: options,
      passedParams: obj,
    });

    const response = await fetch(`${obj.hostPath}/${obj.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    const data = await response.text();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};


/**
 * Handles HTTP fetch requests and returns a FunctionalResult.
 * @param {AuthenticatedAPIModel} apiModel - the Api Details to pass in
 * @param {T} model - The model to pass in the body of the request.
 * @param {T} defaultObj - The default object to return if the fetch is successful but no data is returned.
 * @returns {Promise<FunctionalResult<T>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const fetchActionWithBody = async <T>(
  apiModel: AuthenticatedAPIModel,
  model: any,
  defaultObj?: T
): Promise<FunctionalResult<T>> => {
  try {
    const method = apiModel.method || 'GET';
    const options: RequestInit = {
      method: method,
      headers: apiModel.userId
        ? {
          'X-User-Identifier': apiModel.userId,
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(apiModel.accessToken)
        }
        : {
          'Content-Type': 'application/json',
          [authorizationHeader]: createBearerToken(apiModel.accessToken)
        },
      body: JSON.stringify({ item: model }),
    };

    SharedLogger(LogLevel.Info, {
      message: 'Fetch With Body Called',
      Options: options,
      passedParams: apiModel,
    });
    const response = await fetch(`${apiModel.hostPath}/${apiModel.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    if (response.status === 204) {
      return FunctionalResult.Success(defaultObj);
    }

    const data = await response.json();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Handles HTTP fetch requests with a body and no auth and returns a FunctionalResult.
 * @param {BaseAPIModel} apiModel - the Api Details to pass in
 * @param {T} model - The model to pass in the body of the request.
 * @param {T} defaultObj - The default object to return if the fetch is successful but no data is returned.
 * @returns {Promise<FunctionalResult<T>>} - A FunctionalResult representing the outcome of the fetch operation.
 */
export const fetchActionNoAuthWithBody = async <T>(
  apiModel: BaseAPIModel,
  model: any,
  defaultObj?: T
): Promise<FunctionalResult<T>> => {
  try {
    const method = apiModel.method || 'GET';
    const options: RequestInit = {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ item: model }),
    };

    SharedLogger(LogLevel.Info, {
      message: 'Fetch No Auth With Body Called',
      Options: options,
      passedParams: apiModel,
    });
    
    const response = await fetch(`${apiModel.hostPath}/${apiModel.apiPath}`, options);

    if (!response.ok) {
      return await readFailuresAsync(response);
    }

    if (response.status === 204) {
      return FunctionalResult.Success(defaultObj);
    }

    const data = await response.json();

    return FunctionalResult.Success(data);
  } catch (error: any) {
    return FunctionalResult.Error(error.message);
  }
};

/**
 * Tries to read the body for the failures
 * @param {Response} Response - The response object.
 * @returns A detailed Error Functional response
 */
const readFailuresAsync = async <T>(
  response: Response
): Promise<FunctionalResult<T>> => {
  try {
    if (response.status === 404) {
      return FunctionalResult.Error('Not found', 404);
    }
    const json = await response.json();

    if (response.status === 500 || response.status === 400) {
      return FunctionalResult.Error(
        json.correlationId,
        json.error === undefined || json.error === null // We cant just check for falsely as enums start at 0 
          ? response.status
          : json.error
      );
    } else {
      return FunctionalResult.Error(json.correlationId, response.status);
    }
  } catch (error: any) {
    return FunctionalResult.Error(
      `Request failed with status ${response.status}`
    );
  }
};
