import React from 'react';
import { TiTick, TiTimes } from 'react-icons/ti';
import { FaArrowRight, FaXmark, FaBan } from 'react-icons/fa6';
import { StatusBlockProps } from './StatusBlock.types';

import './StatusBlock.css';

const StatusBlock: React.FC<StatusBlockProps> = (props) => {
  const readOnly = <FaBan strokeWidth={0} className="state-icon readOnly" />;
  const iconMappings = {
    tabPositive: <FaArrowRight strokeWidth={0} className="state-icon" />,
    positive: <TiTick strokeWidth={0} className="state-icon" />,
    negative: <FaXmark strokeWidth={0} className="state-icon" />,
    neutral: null,
    tabStatic: null,
  };

  const icon = iconMappings[props.boxState] || null;

  const handleClick = () => {
    if (props.onclick) {
      props.onclick(); 
    }
  };

  return (
    <div
      onClick={handleClick}
      key={`container_checkbox_${props.id}`}
      className={`state-box ${props.boxState} ${
        !props.allowHover ? '' : 'allowHover'
      } light ${props.boxSize} ${
        props.showIcon === true ? '' : 'lexxic-text'
      } ${!props.className ? '' : props.className}`}
    >
      {props.showIcon === true ? props.readonly === true? readOnly : icon : props.content}
    </div>
  );
};

export default StatusBlock;
