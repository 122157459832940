import React from 'react';

import { SideNavLinkProps } from './SideNav.types';

export const SideNavLink: React.FC<SideNavLinkProps> = (props) => {
  return (
    <div id={props.linkId} aria-label={props.labelText} className="sideNav__LinkButton" onClick={() => { if (props.onClick) props.onClick(props.to, props.linkId) }} >
      {props.children}
      <span className="sideNav__LinkButton__text">{props.labelText}</span>
    </div>
  );
};

export default SideNavLink;
