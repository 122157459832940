import { useState, useRef } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { IUserClaims } from './IUserClaims.types';
import { RetrieveUserClaimsFunction } from './UserClaimsState.types';
import { SystemUserClaims } from './UserClaimsContextType.types';

const claimsKey = 'claims';

const useUserClaims = (retrieveUserClaims : RetrieveUserClaimsFunction) => {
  const [userClaims, setUserClaims] = useState<SystemUserClaims>({ user: null, loaded: false, authorised: false });
  const [initialisationComplete, setInitialisationComplete] = useState(false);
  const claimsStorage = useRef(false);

  const saveClaims = (userClaims : IUserClaims) => {
    localStorage.setItem(claimsKey, JSON.stringify(userClaims));
    setUserClaims({ user: userClaims, loaded: true, authorised: true });
    setInitialisationComplete(true);
  };

  const removeClaims = () => {
    localStorage.removeItem(claimsKey);
    setUserClaims({ user: null, loaded: false, authorised: false });
  };

  const setUnauthorised = () => {
    localStorage.removeItem(claimsKey);
    setUserClaims({ user: null, loaded: true, authorised: false });
  };

  const retrieveClaims = () => {
    const storedClaims = localStorage.getItem(claimsKey);

    if (storedClaims === null) {
      // no claims stored in session storage
      return null;
    }

    let parsedClaims = JSON.parse(storedClaims);

    if (parsedClaims === null) {
      // stored claims cannot be parsed
      localStorage.removeItem(claimsKey);
      return null;
    }

    return parsedClaims;
  };

  const loadClaimsAsync = async (account : AccountInfo) => {
    if (userClaims.user === null && !claimsStorage.current) {
      if (account === null) {
        return;
      }

      let storedClaims = retrieveClaims();

      if (storedClaims && storedClaims.emailAddress === account.username) {
        claimsStorage.current = true;
        setUserClaims({ user: storedClaims, loaded: true, authorised: true });
        setInitialisationComplete(true);
        return;
      }

      const retrievalResult = await retrieveUserClaims(account.localAccountId, account.username, account.idToken!);

      if (retrievalResult.isFailure) {
        setUnauthorised();
        setInitialisationComplete(true);
        return;
      }

      saveClaims(retrievalResult.result!);
      setUserClaims({ user: retrievalResult.result!, loaded: true, authorised: true });
    }
  };

  return { userClaims, initialisationComplete, loadClaimsAsync, removeClaims, setUnauthorised, saveClaims, retrieveClaims };
};

export default useUserClaims;