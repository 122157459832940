// src/components/SideBar/SideBar.tsx
import React from 'react';
import { FaGear } from 'react-icons/fa6';
import { FaSignOutAlt } from 'react-icons/fa';
import { SideNavProps } from './SideNav.types';
import SideNavLink from './SideNavLink';
import './SideNav.css';

export const SideNav = ({ ...props }: SideNavProps) => {
  const [expanded, setExpanded] = React.useState(true);
  let mode = expanded ? 'sideNav--expanded' : 'sideNav--collapsed';

  const handleCollapse = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={`sideNavContainer ${mode}`}>
        <div className="sideNavLogoMenu">
          {expanded ? (
            <div
              className="sideNavLogoMenu__imageContainer"
              title="Lexxic Logo"
            >
              <img src={props.image} height={64} width={113} alt="Lexxic Logo" />
            </div>
          ) : (
            <div className="sideNavLogoMenu__imageContainer--collapsed">
              <img src={props.image} height={20} width={38} alt="Lexxic Logo" />
            </div>
          )}

          {props.buttons
            ? props.buttons.map((button, i) => (
              <SideNavLink
                key={`navItem_${i}`}
                linkId={`navItem_${i}`}
                to={button.link}
                labelText={button.label}
                onClick={props.onClick}
              >
                {React.createElement(button.icon)}
              </SideNavLink>
            ))
            : null}
        </div>
        <div className="sideNavBottomMenu">
          <div
            className="sideNav__LinkButton"
            onClick={() => {
              if (props.signOutClick) props.signOutClick();
            }}
          >
            <FaSignOutAlt />
            <span className="sideNav__LinkButton__text">Sign Out</span>
          </div>

          <div onClick={handleCollapse} className="sideNav__LinkButton">
            <FaGear />
            <span className="sideNav__LinkButton__text">Close Menu</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNav;
