import React from 'react';
import BaseBox from '../../ReusableBlocks/BaseBox/BaseBox';
import { CommonModelProps } from './CommonModal.types';

import './CommonModal.css';
const CommonModal: React.FC<CommonModelProps> = (props) => {
  return (
    <div
      hidden={!props.show}
      className={`modal ${
        props.modalType === 'ErrorMessage' ? 'negative' : 'positive'
      }`}
    >
      <div className="modal-content">
        <BaseBox
          modalKey={props.modalKey}
          bodyText={props.bodyText}
          heading={props.headerText}
          controlState={props.modalType === 'ErrorMessage' ? 'negative' : 'positive'}
          primaryButton={props.buttonDetails}
        >{props.children}</BaseBox>
      </div>
    </div>
  );
};

export default CommonModal;
