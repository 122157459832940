import React, { FC, useState } from 'react';
import { FormContainerProps } from './FormContainer.types';
import {
  BsArrowRightSquareFill,
  BsArrowLeftSquareFill,
  BsGear,
} from 'react-icons/bs';

import './FormContainer.css';
import Button from '../../Common/Button/Button';
import StatusBlock from '../../ReusableBlocks/StatusBlock/StatusBlock';

const FormContainer: FC<FormContainerProps> = ({
  nextLabel,
  backLabel,
  pages,
  pageChangeAction,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const renderButtons = () => {
    return (
      <div
        key="FormContainer_ButtonHolder"
        className="form-Container-button-container"
      >
        {pages.length === 0 ? null : activeTab > 0 ? (
          <Button
            itemKey="container_back"
            mode="neutral"
            label={backLabel ?? 'Back'}
            clickEvent={(e) => changePage(activeTab - 1)}
          >
            <BsArrowLeftSquareFill className="icon tab-button-icon" />
          </Button>
        ) : null}
        {pages.length !== 0 && activeTab === pages.length - 1 ? null : (
          <Button
            itemKey="container_forward"
            mode="positive"
            label={nextLabel ?? 'Next'}
            clickEvent={(e) => changePage(activeTab + 1)}
          >
            {activeTab === pages.length - 1 ? (
              <BsGear className="icon" />
            ) : (

              <span className="fix-icon-bg"><BsArrowRightSquareFill className="icon" /></span>
            )}
          </Button>
        )}
      </div>
    );
  };

  const changePage = (num: number) => {
    if (pageChangeAction) {
      pageChangeAction(activeTab, num);
    }

    if (num >= 0 && num < pages.length) {
      setActiveTab(num);
    }
  };

  const renderTabs = () => {
    return (
      <div className="form-Container-tab-container">
        {pages.map((p, i) => {
          return (
            <div
              className={`form-Container-tab ${p.mode === 'tabStatic' ? 'tabStatic' : ''
                } ${i === activeTab ? 'active' : ''
                }`}
              onClick={(e) => {
                changePage(i);
              }} tabIndex={0}
            >
              {p.mode != 'tabStatic' && (
                <StatusBlock
                  boxSize="small"
                  boxState={p.mode}
                  id={`tabMainStatus_${i}`}
                  key={`tabMainStatus_${i}`}
                  content={i + 1}
                  showIcon={p.mode !== 'neutral'}
                />
              )}

              <label className="tab-label">{p.name}</label>
            </div>
          );
        })}
      </div>
    );
  };

  return pages === null || pages === undefined || !pages ? (
    <div key="FormContainer_ErrorHolder">Error</div>
  ) : (
    <div className="form-container" key="FormContainer">
      {renderTabs()}
      <div key="FormContainer_Holder" className="form-Container-body-container">
        {!pages.length
          ? null
          : pages.map((page, i) => {
            return (
              <div key={page.name} hidden={activeTab !== i}>
                {page.content}
              </div>
            );
          })}
      </div>
      {renderButtons()}
    </div>
  );
};

export default FormContainer;
