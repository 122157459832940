// src/pages/Consent/Consent.tsx
import React, { useContext } from 'react';
import currentPolicyText from '../../Settings/currentPolicyText.json';
import usrActions from '../../utils/userUtils/UserActions';
import { useMsal } from '@azure/msal-react';
import {
  fetchIPInfo,
  getHeaderInfo,
} from '../../utils/CommonUtils/CommonHelpers';
import { UserClaimsContext } from '../../sysObjects/Auth/UserClaimsContext';
import UserAgreement from '../../systemComponents/sharedControls/TaskBase/UserAgreement/UserAgreement';

const Consent: React.FC<ConsentProps> = ({ displayAgreement }) => {
  const { userClaims, updateUserClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();

  const userDisagrees = () => {
    var result = window.confirm('Are you sure you reject the agreement? ');

    if (result) {
      alert("You've rejected the agreement");
    }
  };

  const userAgrees = () => {
    fetchIPInfo().then((ipInfo) => {
      let ip = '';

      if (ipInfo.isSuccess) {
        ip = ipInfo.result!;
      }

      usrActions
        .saveConsentAsync(getHeaderInfo(userClaims, instance), ip)
        .then((rst) => {
          if (rst.isFailure) {
            return;
          }

          try {
            usrActions
              .refreshClaimsAsync(getHeaderInfo(userClaims, instance))
              .then((refUser) => {
                if (refUser.isSuccess) {
                  updateUserClaims(refUser.result!);
                }
              });
            console.log('User claims updated successfully');
          } catch (error) {
            console.error('Error updating user claims:', error);
          }
        });
    });
  };

  return (
    <UserAgreement
      agreeEvent={userAgrees}
      disagreeEvent={userDisagrees}
      key={'Agreement'}
      text={currentPolicyText.text}
      displayAgreement={displayAgreement}
    />
  );
};

export default Consent;

interface ConsentProps {
  displayAgreement: boolean;
  id?: string;
}
