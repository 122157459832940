import React from 'react';
import { ButtonProps } from './Button.types';

import './Button.css';

const Button: React.FC<ButtonProps> = ({
  itemKey,
  label,
  clickEvent,
  disabled,
  ariaLabel,
  ariaDescribedBy,
  mode,
  children,
}) => {
  const getClass = () => {
    if (!mode) {
      return '';
    }
    return mode;
  };

  return (
    <button
      key={itemKey}
      type="button"
      className={`lexxic-button ${getClass()}`}
      onClick={clickEvent}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
    >
      {children ? (
        <>
          {children} <label className="lexxic-text btnLabel">{label}</label>
        </>
      ) : (
        <label className="btnLabel">{label}</label>
      )}
    </button>
  );
};

export default Button;
