import React from 'react';
import './Breadcrumbs.css';
import { BreadcrumbsProps } from './Breadcrumbs.types';
import Breadcrumb from './Breadcrumb';



export const Breadcrumbs = ({ ...props }: BreadcrumbsProps) => {
    return (
        <nav className="breadcrumbs">
            <ol className="breadcrumb_list">
                {props.breadcrumbLinks
                    ? props.breadcrumbLinks.map((breadcrumbLink, i) => (

                        <Breadcrumb
                            key={`navItem_${i}`}
                            to={breadcrumbLink.link}
                            labelText={breadcrumbLink.label}
                            onClick={props.onClick}
                        >
                        </Breadcrumb>

                    ))
                    : null}


            </ol>

        </nav>
    )
}

export default Breadcrumbs