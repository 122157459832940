import { LogLevel } from '@azure/msal-browser';
import hostLocal from '../../host.json';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import SharedLogger from '../../systemUtils/common/logging/SharedLogger';

let hostConfig: HostConfig | null = null;

export interface HostConfig {
  path: string;
  azure: AzureConfig;
}

export interface AzureConfig {
  clientId: string;
  signInAuthority: string;
  signUpAuthority: string;
  authorityDomain: string;
  cacheLocation: string;
}

/**
 * Dynamically loads the host configuration at runtime, or uses the statically imported config for local development.
 * @returns {Promise<string>} The host path as a string.
 */
export async function getHostConfigAsync(): Promise<
  FunctionalResult<HostConfig>
> {
  const checker = localStorage.getItem('hostConfig');
  if (checker) {
    return FunctionalResult.Success(JSON.parse(checker));
  }
  if (process.env.NODE_ENV === 'production') {
    try {
      SharedLogger(LogLevel.Info, 'attempting to get the host.json file');
      const response = await fetch(`${window.location.origin}/host.json`);

      if (!response.ok) {
        return FunctionalResult.Error('Failed to load host configuration');
      }

      hostConfig = (await response.json()) as HostConfig;
      localStorage.setItem('hostConfig', JSON.stringify(hostConfig));
      SharedLogger(LogLevel.Info, {
        message: "Successfully loaded host configuration from 'host.json':",
      });

      return FunctionalResult.Success(hostConfig);
    } catch (error) {
      SharedLogger(LogLevel.Error, {
        message: 'Error loading host configuration:',
      });

      return FunctionalResult.Error('Failed to load host configuration');
    }
  } else {
    SharedLogger(LogLevel.Info, {
      message: 'In development, using local host configuration:',
    });
    localStorage.setItem('hostConfig', JSON.stringify(hostLocal));
    return FunctionalResult.Success(hostLocal);
  }
}
