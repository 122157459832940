// src/pages/UserPage/UserPage.tsx
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingDiv from '../../components/LoadingDiv/LoadingDiv';
import { UserClaimsContext } from '../../sysObjects/Auth/UserClaimsContext';
const LoadingPage: React.FC = () => {
  const { userClaims } = useContext(UserClaimsContext);
  const navigate = useNavigate();

  if (userClaims!.user) {
    navigate('/');
  }

  useEffect(() => {
    if (userClaims!.user) {
      navigate('/');
    }
  }, [userClaims!.user, userClaims!.loaded]);

  return (
    <LoadingDiv />
  );
};

export default LoadingPage;
